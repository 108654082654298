import valera from '../../../../../static/videos/main-swiper/valera.mp4'
import corp from '../../../../../static/videos/main-swiper/corp.mp4'
import waves from '../../../../../static/videos/main-swiper/waves.mp4'
import group from '../../../../../static/videos/main-swiper/group.mp4'
import merch from '../../../../../static/videos/main-swiper/merch.mp4'

export const videos = [
  { src: corp },
  { src: merch },
  { src: waves },
  { src: valera },
  { src: group },
]
