import React from 'react'
import Img from 'gatsby-image'
import { SwiperSlide } from 'swiper/react/swiper-react.js'
import { openModal, modalNames } from 'src/shared/features/modal'
import { useSliderSwipe } from 'src/shared/hooks'
import { sliderInMainSettings } from 'src/shared/constants'
import { HBox, Wrapper } from 'src/ui/layout'
import { CustomSliderContainer } from 'src/ui/atoms'
import { Button } from 'src/ui/molecules'
import { SwiperSlider } from 'src/ui/organisms'
import { styled, useTheme } from 'src/ui/theme'
import { Body, Header, Label } from 'src/ui/typography'
import type { TSliderItem, TSliderText } from './types'
import { AchievementCard } from 'src/ui/molecules/achievement-card'
import { videos } from './constants'
import { useNavigate } from '@reach/router'

const BlockContainer = styled(CustomSliderContainer)`
  position: relative;
  width: 100%;

  .swipe {
    height: 100%;
  }

  .swiper-pagination {
    width: initial !important;
    left: 24px !important;
    height: 40px;
    top: 10px;
    align-items: start;
    z-index: 4;
  }

  button.swiper-pagination-bullet svg circle#spinner {
    animation-duration: 6s;
  }
`

const SliderItem = styled.div`
  height: 385px;
  width: 100%;
`

const Video = styled.video`
  display: block;
  position: absolute !important;
  object-fit: cover;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  margin: 0 auto;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
  z-index: 2;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 24px;
`

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const StyledBody = styled(Body)`
  max-width: 416px;
  white-space: pre-line;
`

const Title = styled.span`
  white-space: pre-line;
`

const ColorTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #4f4ff4;
  mix-blend-mode: hard-light;
  z-index: 1;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

type Props = {
  images: TImages
  sliderItems: TSliderItem[]
  sliderText: TSliderText
}

export const HeroBlock = ({ images, sliderItems, sliderText }: Props) => {
  const { title, text } = sliderText

  const theme = useTheme()
  const { sliderRef, handleTouchStart, handleTouchEnd } = useSliderSwipe()
  const navigate = useNavigate()

  return (
    <Container>
      <BlockContainer>
        <SwiperSlider
          ref={sliderRef}
          settings={sliderInMainSettings}
          customPagination
        >
          {videos.map(({ src }, index) => {
            if (src) {
              return (
                <SwiperSlide key={index}>
                  <SliderItem>
                    <Wrapper>
                      <Video loop playsInline key={src} autoPlay muted>
                        <source src={src} type="video/mp4" />
                        Your browser does not support the video tag.
                      </Video>
                    </Wrapper>
                  </SliderItem>
                </SwiperSlide>
              )
            }
          })}
          <ContentWrapper
            onClick={(e) => e.stopPropagation()}
            tabIndex={0}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            <Content>
              <InfoBlock>
                <Header size="h4" as="h4" color={theme.colors.white.primary}>
                  <Title>{title}</Title>
                </Header>
                <HBox height={16} />
                <StyledBody size="secondary" color={theme.colors.white.primary}>
                  {text}
                </StyledBody>
                <HBox height={24} />
                <Button variant="contrast" onClick={() => navigate('/career/')}>
                  Вакансии
                </Button>
              </InfoBlock>
            </Content>
          </ContentWrapper>
          <ColorTop />
          <div className="swiper-pagination"></div>
        </SwiperSlider>
      </BlockContainer>
      <AchievementCard
        backgroundImage={images['head-hunter'] ?? ''}
        variant={'hh'}
      />
      <AchievementCard variant={'rbk'} backgroundImage={images['rbk']} />
      <AchievementCard variant="forbes" backgroundImage={images['forbes']} />
    </Container>
  )
}
