import React from 'react'

import { SwiperSlide } from 'swiper/react/swiper-react.js'
import { useNavigate } from '@reach/router'

import { openModal, modalNames } from 'src/shared/features/modal'
import { useSliderSwipe } from 'src/shared/hooks'
import { sliderInMainSettings } from 'src/shared/constants'

import { HBox, Wrapper } from 'src/ui/layout'
import { CustomSliderContainer } from 'src/ui/atoms'
import { Button } from 'src/ui/molecules'
import { SwiperSlider } from 'src/ui/organisms'
import { styled, useTheme } from 'src/ui/theme'
import { Body, Header, Label } from 'src/ui/typography'

import type { TSliderItem, TSliderText } from './types'
import { AchievementCard } from 'src/ui/molecules/achievement-card'
import { videos } from './constants'

const Grid1 = styled.div`
  height: 686px;
  display: grid;
  grid-template-columns: 50% 50%;
`

const Grid2 = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
`
const Grid3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const GridItem = styled.div``

const Container = styled(CustomSliderContainer)`
  position: relative;

  .swiper-pagination {
    width: initial;
    left: 64px;
    top: 50px !important;
    align-items: start;
  }

  button.swiper-pagination-bullet svg circle#spinner {
    animation-duration: 6s;
  }
`

const SliderItem = styled.div`
  height: 566px;
`

const Video = styled.video`
  display: block;
  position: absolute !important;
  object-fit: cover;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  margin: 0 auto;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
  z-index: 3;
`
const ColorTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #4f4ff4;
  mix-blend-mode: hard-light;
  z-index: 1;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  flex-grow: 1;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 64px;
`

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const StyledBody = styled(Body)`
  max-width: 416px;
  white-space: pre-line;
`

const Title = styled.span`
  white-space: pre-line;
`

type Props = {
  sliderItems: TSliderItem[]
  sliderText: TSliderText
  images: TImages
}

export const HeroBlock = ({ images, sliderItems, sliderText }: Props) => {
  const theme = useTheme()
  const { sliderRef, handleMouseDown, handleMouseUp } = useSliderSwipe()
  const navigate = useNavigate()

  const { title, text } = sliderText

  return (
    <Grid1>
      <GridItem style={{ paddingBottom: '120px' }}>
        <Container>
          <SwiperSlider
            ref={sliderRef}
            settings={sliderInMainSettings}
            customPagination
          >
            {videos.map(({ src }, index) => {
              if (src) {
                return (
                  <SwiperSlide key={index}>
                    <SliderItem>
                      <Wrapper>
                        <Video loop playsInline key={src} autoPlay muted>
                          <source src={src} type="video/mp4" />
                          Your browser does not support the video tag.
                        </Video>
                      </Wrapper>
                    </SliderItem>
                  </SwiperSlide>
                )
              }
            })}
          </SwiperSlider>
          <ContentWrapper
            tabIndex={0}
            onMouseUp={handleMouseUp}
            onMouseDown={handleMouseDown}
          >
            <Content>
              <div className="swiper-pagination"></div>

              <InfoBlock>
                <Header size="h2" as="h1" color={theme.colors.white.primary}>
                  <Title>{title}</Title>
                </Header>

                <HBox height={24} />
                <StyledBody color={theme.colors.white.primary}>
                  {text}
                </StyledBody>
                <HBox height={48} />
                <Button variant="contrast" onClick={() => navigate('/career/')}>
                  Вакансии
                </Button>
              </InfoBlock>
            </Content>
          </ContentWrapper>
          <ColorTop />
        </Container>
      </GridItem>
      <Grid2>
        <GridItem>
          <AchievementCard
            backgroundImage={images['head-hunter'] ?? ''}
            variant={'hh'}
          />
        </GridItem>
        <Grid3>
          <GridItem style={{ paddingBottom: '60px' }}>
            <AchievementCard variant={'rbk'} backgroundImage={images['rbk']} />
          </GridItem>
          <GridItem>
            <AchievementCard
              variant="forbes"
              backgroundImage={images['forbes']}
            />
          </GridItem>
        </Grid3>
      </Grid2>
    </Grid1>
  )
}
