import React, { ReactNode, useState } from 'react'
import { Icons } from 'src/ui/icons'
import { styled, useTheme } from 'src/ui/theme'
import { AchievementVariant } from './types'
import { HBox } from 'src/ui/layout'
import { Body } from 'src/ui/typography'
import { FluidObject } from 'gatsby-image'
import Img from 'gatsby-image/withIEPolyfill'
import { useDimensions } from 'src/ui/dimensions'
import { LinkIcon } from 'src/ui/icons/link'

const Wrapper = styled.a`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white.primary};
  justify-content: flex-end;
  flex-direction: column;
  overflow: hidden;
  padding: 40px;
  text-decoration: none;
  flex-grow: 0;
  flex-shrink: 0;
  gap: 4px;
`

const StyledHeader = styled(Body)`
  font-size: 24px;
  line-height: 27px;
  font-weight: 500;
`

const StyledDescription = styled(Body)<{ isHover: boolean }>`
  opacity: ${({ isHover }) => (isHover ? 1 : 0.6)};
  transition: opacity 0.5s ease;
`

const ColorTop = styled.div<{ variant: AchievementVariant }>`
  background: ${({ variant }) =>
    variant === 'hh'
      ? `linear-gradient(${colorTop[variant].from}, ${colorTop[variant].to})`
      : `radial-gradient(${colorTop[variant].from}, ${colorTop[variant].to})`};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`

const LinkIconContent = styled.div<{ isHover: boolean; isMobile: boolean }>`
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 5;
  opacity: ${({ isHover, isMobile }) => (isHover || isMobile ? 1 : 0)};
  transition: opacity 0.5s ease;
`

const LocationContent = styled.div<{ isHover: boolean; isMobile: boolean }>`
  position: absolute;
  left: 40px;
  top: 40px;
  z-index: 1;
  opacity: ${({ isHover, isMobile }) => (isHover || isMobile ? 1 : 0)};
  transition: opacity 0.5s ease;
`

const StyledLocationBody = styled(Body)`
  opacity: 0.6;
`

const BackgroundImage = styled.div<{ isHover: boolean }>`
  position: absolute;
  width: 500px;
  height: 500px;
  bottom: ${({ isHover }) => (isHover ? `120px` : '80px')};
  left: -80px;
  transition: bottom 0.5s ease;
`

const Content = styled.div`
  z-index: 2;
`

const Image = styled(Img)`
  position: absolute !important;
  object-fit: cover;
  width: 500px;
  height: 500px;
  z-index: 1;
`

const TitleByVariantMap: Record<AchievementVariant, string> = {
  rbk: '1-е место в РБК ',
  hh: '1-е место в HeadHunter',
  forbes: 'Топ лучших\nработодателей',
}

export const achievementsDescriptions: Record<AchievementVariant, string> = {
  hh: 'Лучшие работодатели России в IT-компаниях  среднего размера',
  forbes: 'Золотая группа версия Forbes 2024',
  rbk: 'Рейтинг работодателей РБК 2024',
}

const locationCaption: Partial<Record<AchievementVariant, string>> = {
  hh: '*Калининградская область',
}

const colorTop: Record<AchievementVariant, { from: string; to: string }> = {
  rbk: { from: 'rgba(12, 195, 153, 1)', to: 'rgba(4, 171, 99, 1)' },
  hh: { from: 'rgba(196, 35, 43, 1)', to: 'rgba(147, 0, 7, 1)' },
  forbes: { from: 'rgba(125, 136, 167, 1)', to: 'rgba(91, 114, 129, 1)' },
}

const iconsByVariantMap: Record<AchievementVariant, ReactNode> = {
  rbk: Icons.RbkIcon(),
  hh: Icons.HhIcon(),
  forbes: Icons.ForbesIcon(),
}

const BetweenBox = styled(HBox)`
  transition: 0.5s ease;
`

const hrefByVariantMap: Record<AchievementVariant, string> = {
  rbk: 'https://drive.google.com/file/d/19ZJyFndjq13dzZcASEcRtzwdBuMIdn4O/view',
  hh:
    'https://rating.hh.ru/history/rating2023/summary?tab=regular&city=%D0%9A%D0%B0%D0%BB%D0%B8%D0%BD%D0%B8%D0%BD%D0%B3%D1%80%D0%B0%D0%B4%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C',
  forbes: 'https://www.forbes.ru/ratings/best-employers',
}

type TProps = {
  variant: AchievementVariant
  className?: string
  backgroundImage: FluidObject
  expandable?: boolean
}

export const AchievementCard = ({
  variant,
  className,
  backgroundImage,
}: TProps) => {
  const theme = useTheme()
  const { isMobile } = useDimensions()
  const [isHover, setIsHover] = useState(false)
  return (
    <Wrapper
      href={hrefByVariantMap[variant]}
      className={className}
      target="_blank"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <BackgroundImage isHover={isHover}>
        <Image fluid={backgroundImage} />
      </BackgroundImage>
      {locationCaption[variant] && (
        <LocationContent isHover={isHover} isMobile={isMobile}>
          <StyledLocationBody
            size="secondary"
            weight="medium"
            color={theme.colors.white.primary}
          >
            {locationCaption[variant]}
          </StyledLocationBody>
        </LocationContent>
      )}
      <ColorTop variant={variant} />
      <LinkIconContent isHover={isHover} isMobile={isMobile}>
        <LinkIcon />
      </LinkIconContent>
      <Content>
        {iconsByVariantMap[variant]}
        <BetweenBox height={isHover ? 30 : 24} />
        <StyledHeader color={theme.colors.white.primary}>
          {TitleByVariantMap[variant]}
        </StyledHeader>
        <BetweenBox height={isHover ? 6 : 1} />
        <StyledDescription
          isHover={isHover}
          size="secondary"
          color={theme.colors.white.primary}
        >
          {achievementsDescriptions[variant]}
        </StyledDescription>
      </Content>
    </Wrapper>
  )
}
